export enum ResourseNames {
  users = 'users',
  devices = 'devices',
  models = 'models',
  otaDevices = 'ota/devices',
  cartridges = 'cartridges',
  hardwares = 'hardwares',
  firmwares = 'firmwares',
  admins = 'admins',
  puffs = 'puffs',
  featureToggles = 'feature-toggle',
  warrantiesMetaLite = 'warranties/meta-lite',
  warrantiesMetaSmart = 'warranties/meta-smart',
}
