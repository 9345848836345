import { Menu, usePermissions } from 'react-admin';
import { Roles, Permissions } from 'types';
import { hasPermission } from '../../validators';

export const SidebarMenu = () => {
  const { permissions } = usePermissions();
  const role = permissions as Roles;

  if (!role) return null;

  return (
    <Menu>
      {hasPermission(role, Permissions.ReadAdmins) && <Menu.Item to="/admins" primaryText="Админы" />}
      {hasPermission(role, Permissions.ReadUsers) && <Menu.Item to="/users" primaryText="Пользователи" />}
      {hasPermission(role, Permissions.ReadDevices) && <Menu.Item to="/devices" primaryText="Устройства" />}
      {hasPermission(role, Permissions.ReadFirmwares) && <Menu.Item to="/firmwares" primaryText="Прошивки" />}
      {hasPermission(role, Permissions.ReadHardwares) && <Menu.Item to="/hardwares" primaryText="Hardware" />}
      {hasPermission(role, Permissions.ReadCartridges) && <Menu.Item to="/cartridges" primaryText="Картриджи" />}
      {hasPermission(role, Permissions.ReadModels) && <Menu.Item to="/models" primaryText="Модели" />}
      {hasPermission(role, Permissions.ReadOtaDevices) && <Menu.Item to="/ota/devices" primaryText="OTA устройства" />}
      {hasPermission(role, Permissions.ReadWarranties) && (
        <Menu.Item to="/warranties/meta-lite" primaryText="Гарантии Meta Lite" />
      )}
      {hasPermission(role, Permissions.ReadWarranties) && (
        <Menu.Item to="/warranties/meta-smart" primaryText="Гарантии Meta Smart" />
      )}
      {hasPermission(role, Permissions.ReadFeatureToggles) && (
        <Menu.Item to="/feature-toggle" primaryText="Управление фичами" />
      )}
    </Menu>
  );
};
